import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

const CustomSelect = ({ options, onChange, value, width, disabled }) => {
  return (
    <Autocomplete
      className="w-full"
      onChange={(e, value) => {
        onChange({ target: { value: value?.value } });
      }}
      disabled={disabled}
      value={
        options.find((option) => option?.value === value) || {
          value: "",
          label: "",
        }
      }
      options={options}
      sx={{
        width: width ? width : "150px",
        "@media (max-width: 768px)": {
          width: "100%",
        },
        //decrease padding
        "& .MuiOutlinedInput-root": {
          padding: "0px",
          borderRadius: "0px",
          "& fieldset": {
            borderWidth: "2px",
            borderColor: "black",
          },
          //hover color
          "&:hover fieldset": {
            borderColor: "black",
          },
          //focused color
          "&.Mui-focused fieldset": {
            borderColor: "black",
          },
        },
      }}
      renderOption={(props, option, state) => {
        return (
          <>
            <li
              {...props}
              style={{
                backgroundColor: state.selected ? "#fff101" : "white",
              }}
            >
              {option.label || option}
              {state.selected && (
                <div className="h-2 w-2 rounded-full bg-secondary"></div>
              )}
            </li>
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select"
          // decrease padding
        />
      )}
    />
  );
};

export default CustomSelect;
