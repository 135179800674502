import { CircularProgress } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";

const Loading = () => {
  return ReactDOM.createPortal(
    <div
      className="fixed z-50
  top-1/2 left-1/2 transform -translate-x-1/2 bg-opacity-30 -translate-y-1/2 h-[100vh] w-[100vw] flex justify-center items-center bg-primary
"
    >
      <CircularProgress />
    </div>,
    document.getElementById("portal")
  );
};

export default Loading;
