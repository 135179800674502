import { Typography } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import useStore from "../utils/store";
import { useEffect } from "react";

const Home = () => {
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  console.log(user);
  useEffect(() => {
    if (user) {
      if (user.type === "Superadmin") {
        navigate("/superadmin");
      }
    } else {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="flex flex-col gap-6  items-center">
      <Typography variant="h1">Links</Typography>

      <Link to="/login" className="border-b border-primary">
        Login
      </Link>
    </div>
  );
};

export default Home;
