import React from "react";
import { Link } from "react-router-dom";

const ImageView = ({ img }) => {
  const [show, setShow] = React.useState(false);
  return (
    <div>
      <Link to={img} target="_blank" className=" ">
        <img src={img} alt="" className="w-20 h-20 object-cover" />
      </Link>

      {/* {show && (
        <div
          className="fixed top-0 left-0 cursor-pointer w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
          onClick={() => setShow(!show)}
        >
          <img src={img} alt="" className="w-1/2 aspect-auto object-cover" />
        </div>
      )} */}
    </div>
  );
};

export default ImageView;
