import React, { useEffect, useState } from "react";
import Dash from "../../components/Dash";
import {
  getallSuperAdmin,
  deleteSuperStaffPayment,
  deleteSuperStaffReceived,
  updateSuperStaffPayment,
  updateSuperStaffReceived,
} from "../../utils/api";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { toast } from "react-toastify";
import CustomModal, { ModalInput } from "../../components/Modal";
import { Link } from "react-router-dom";
import Layout5 from "../../components/Layout/Layout5";
import DeleteModal from "../../components/modals/DeleteModal";
import StaffMessage from "../../components/StaffMessage";

const SuperDashboard = () => {
  const [dash, setDash] = useState([]);

  const [dashboardCount, setDashCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit2, setEdit2] = useState(null);

  const getAdminDashboardHandler = async () => {
    setLoading(true);
    const [success, data] = await getallSuperAdmin();
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAdminDashboardHandler();
  }, []);

  return (
    <Layout5
    // reload={() => {
    //   getAdminDashboardHandler();
    //   getAllOrdersHandler();
    //   getProductsHandler();
    //   getCustomers();
    // }}
    >
      {loading && <Loading />}
      <div className="flex gap-3 flex-col md:flex-row flex-wrap">
        <button
          className="flex-1"
          onClick={() => {
            setDashCount({
              type: "Staff",
              data: dash?.totalArray,
            });
          }}
        >
          <Dash title={"Staff"} value={dash?.totalStaff} />
        </button>
        <button
          onClick={() => {
            setDashCount({
              type: "Payment",
              data: dash?.payment,
            });
          }}
          className="flex-1"
        >
          <Dash title={"Payment"} value={dash?.totalPayment?.toFixed(2)} />
        </button>
        <button
          onClick={() => {
            setDashCount({
              type: "Received",
              data: dash?.received,
            });
          }}
          className="flex-1"
        >
          <Dash title={"Received"} value={dash?.totalRecived?.toFixed(2)} />
        </button>
        <button
          className="flex-1"
          onClick={() => {
            setDashCount({
              type: "Interest",
              data: dash?.interestArray,
            });
          }}
        >
          <Dash title={"Interest"} value={dash?.totalInterest?.toFixed(2)} />
        </button>
        <button
          className="flex-1"
          onClick={() => {
            setDashCount({
              type: "Balance",
              data: dash?.totalArray,
            });
          }}
        >
          <Dash title={"Balance"} value={dash?.totalBalance?.toFixed(2)} />
        </button>
      </div>
      {dashboardCount && (
        <>
          <h1 className="text-2xl font-bold my-3">{dashboardCount?.type}</h1>
          {dashboardCount?.type === "Interest" ? (
            <Table
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  render: (item) => (
                    <Link
                      to={`/superadmin/staff/${item?._id}`}
                      className="underline"
                    >
                      {item?.name}
                    </Link>
                  ),
                },
                {
                  title: "Phone Number",
                  dataIndex: "phoneNumber",
                },

                {
                  title: "Address",
                  dataIndex: "address",
                },
                {
                  title: "Card Number",
                  dataIndex: "cardNumber",
                },
                {
                  title: "Interest",
                  dataIndex: "interest",
                },
                {
                  title: "PDF",
                  link: `/super-admin/pdf/dash/${dashboardCount?.type}`,
                },
              ]}
              data={dashboardCount?.data}
            />
          ) : dashboardCount?.type === "Balance" ? (
            <Table
              columns={[
                {
                  title: "Name",

                  td: (item) => Td(item, item?.name, true),
                },
                {
                  title: "Phone Number",
                  td: (item) => Td(item, item?.phoneNumber),
                },
                {
                  title: "Address",
                  td: (item) => Td(item, item?.address),
                },
                {
                  title: "Card Number",
                  td: (item) => Td(item, item?.cardNumber),
                },
                {
                  title: "Balance",
                  td: (item) =>
                    Td(
                      item,
                      item?.balance > 0
                        ? (item?.balance - item?.interest)?.toFixed(2)
                        : item?.balance?.toFixed(2)
                    ),
                },
                {
                  title: "Day",
                  td: (item) => Td(item, item?.day),
                },

                {
                  title: "PDF",
                  link: `/super-admin/pdf/dash/${dashboardCount?.type}`,
                },
              ]}
              data={dashboardCount?.data}
            />
          ) : dashboardCount?.type === "Staff" ? (
            <StaffMessage dashboardCount={dashboardCount} />
          ) : (
            <Table
              columns={[
                {
                  title: "Date",

                  render: (item) => {
                    return new Date(item?.createdAt).toLocaleDateString();
                  },
                },
                {
                  title: "Staff Name",

                  render: (item) => (
                    <Link
                      to={`/superadmin/staff/${item?.staff?._id}`}
                      className="underline"
                    >
                      {item?.staff?.name}
                    </Link>
                  ),
                },
                {
                  title: "Phone Number",
                  td: (item) => Td(item, item?.staff?.phoneNumber),
                },
                {
                  title: "Description",
                  dataIndex: "description",
                },
                {
                  title: "Total",
                  dataIndex: "amount",
                },
                {
                  title: "Actions",
                  dataIndex: "actions",
                  render(item) {
                    return (
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-2 ">
                          <button
                            onClick={() => {
                              setOpen(true);
                              setEdit2(item);
                            }}
                            className="border border-black px-4 py-1 bg-yellow-400 text-xl "
                          >
                            Edit
                          </button>
                          <DeleteModal
                            onClick={async () => {
                              setLoading(true);
                              const [success, data] =
                                item?.paymentType === "Payment"
                                  ? await deleteSuperStaffPayment(item?._id)
                                  : await deleteSuperStaffReceived(item?._id);
                              if (success) {
                                getAdminDashboardHandler();
                                setDashCount(null);
                                toast.success("Deleted");
                              }
                              setLoading(false);
                            }}
                            loading={loading}
                          />
                          {/* <button
                        
                          className="border border-black  py-1 bg-red-600 px-4 text-xl "
                        >
                          {loading ? "Loading..." : "Delete"}
                        </button> */}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title: "PDF",
                  link: `/super-admin/pdf/dash/${dashboardCount?.type}`,
                },
              ]}
              data={dashboardCount?.data}
            />
          )}
        </>
      )}

      <CustomModal
        open={open}
        setOpen={setOpen}
        onClick={async () => {
          const newData = {
            ...edit2,
            staffId: edit2?.staff?._id,
          };
          const [success, data] =
            edit2.paymentType === "Payment"
              ? await updateSuperStaffPayment(edit2?._id, edit2)
              : await updateSuperStaffReceived(edit2?._id, edit2);
          if (success) {
            setEdit2(null);
            setOpen(false);
            setDashCount(null);
            getAdminDashboardHandler();
            toast.success("Updated Staff");
          }
        }}
      >
        <ModalInput
          title={"Description"}
          value={edit2?.description}
          onChange={(e) => {
            setEdit2({
              ...edit2,
              description: e.target.value,
            });
          }}
        />

        <ModalInput
          title={"Amount"}
          value={edit2?.amount}
          onChange={(e) => {
            setEdit2({
              ...edit2,
              amount: e.target.value,
            });
          }}
        />
        {/* <div className="flex items-center justify-between gap-2">
          <div className="">Payment</div>
          <CustomSelect
            width={"255px"}
            onChange={(e) => {
              setEdit2({
                ...edit2,
                paymentType: e.target.value,
              });
            }}
            options={[
              { value: "Received", label: "Received" },
              { value: "Payment", label: "Payment" },
              { value: "Salary", label: "Salary" },
            ]}
            value={edit2?.paymentType}
            disabled={edit2?.paymentType === "Salary"}
          />
        </div> */}
      </CustomModal>
    </Layout5>
  );
};

export default SuperDashboard;

const getcolor = (count) => {
  if (count <= 150) {
    return "bg-green-500";
  }
  if (count > 150 && count <= 180) {
    return "bg-yellow-500";
  }
  if (count > 180) {
    return "bg-red-500";
  }
};

export const Td = (item, children, link) => {
  const color = getcolor(item?.day);

  return (
    <td className={`px-2 py-1 whitespace-nowrap border border-black ${color}`}>
      {link ? (
        <Link to={`/superadmin/staff/${item?._id}`} className="underline">
          {children}
        </Link>
      ) : (
        children
      )}
    </td>
  );
};
