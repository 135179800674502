import { Button, Modal } from "@mui/material";

const CustomModal = ({
  open,
  setOpen,
  children,
  onClick,
  loading,
  title,
  button,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div
        className="w-[95%] md:w-[35rem] max-h-[95dvh] overflow-y-auto  bg-[#fff685] border-2 border-black rounded-lg  py-10 px-6 flex flex-col gap-6
      top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute
  "
      >
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold">{title}</h2>
          <button
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500 hover:text-red-700 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {children}
        <div className="flex items-end justify-end">
          {button ? (
            button
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="w-1/3 "
              onClick={onClick}
            >
              {loading ? "Loading..." : "Save"}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;

export const ModalInput = ({
  title,
  onChange,
  bg,
  value,
  disabled,
  width,
  type,
}) => {
  return (
    <div className="flex gap-1 md:gap-3 md:items-center flex-col md:flex-row justify-between ">
      {title && <label htmlFor={title}>{title}</label>}
      <input
        onChange={onChange}
        type={type ? type : "text"}
        name={title}
        id={title}
        className={`${bg ? "bg-gray-100" : "bg-transparent"} border-2 ${
          width ? width : "md:w-64"
        } border-black py-1  px-2`}
        value={value}
      />
    </div>
  );
};

export const ModalSelect = ({ title, options, onChange, bg, value, width }) => {
  return (
    <div className="flex gap-3 flex-col md:flex-row md:items-center justify-between">
      {title && <label htmlFor={title}>{title}</label>}
      <select
        value={value}
        name={title}
        id={title}
        className={`
        ${bg ? "bg-gray-100" : "bg-transparent"}
        border-2 border-black ${width ? width : "md:w-64"} py-1 `}
        onChange={onChange}
      >
        <option value="">Select</option>
        {options?.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};
