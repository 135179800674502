import { FormGroup, TextField } from "@mui/material";

const CustomInput = ({ title, onChange, type }) => {
  return (
    <FormGroup className="flex flex-col gap-2">
      <label htmlFor="outlined-basic" className="text-sm font-semibold">
        {title}
      </label>
      <TextField
        type={type ? type : "text"}
        onChange={onChange}
        id="outlined-basic"
        label=""
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "8px 14px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#d4dadf",
            },
            "&:hover fieldset": {
              borderColor: "green",
            },
            "&.Mui-focused fieldset": {
              borderColor: "green",
            },
          },
        }}
      />
    </FormGroup>
  );
};

export default CustomInput;
