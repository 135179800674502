import axios from "axios";

// const URL = "https://prabhu-labels-test-backend.vercel.app";
const URL = "https://api.prabhulabels.com";
// const URL = "http://89.116.231.116:8000";

export const login = async (data) => {
  try {
    const res = await axios.post(`${URL}/login`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const resetPaswsord = async (data) => {
  try {
    const res = await axios.post(`${URL}/reset-password`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};
export const getstaffbyid = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/staff/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};
export const getstaffrecevied = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/staff-received/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};
export const getstaffPayment = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/staff-payment/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};
export const getstaffbalance = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/staff-balance/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};
export const getstaffSalary = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/staff-salary/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAdminDashboard = async () => {
  try {
    const res = await axios.get(`${URL}/admin/dashboard`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllStaff = async () => {
  try {
    const res = await axios.get(`${URL}/admin/staff`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const editLocation = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/admin/customerGroup/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};

export const createStaff = async (data) => {
  try {
    const res = await axios.post(`${URL}/admin/staff`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteStaff = async (id) => {
  try {
    const res = await axios.delete(`${URL}/admin/staff/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateStaff = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/admin/staff/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSuperStaff = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/superadmin/staff/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const getAllCustomers = async () => {
  try {
    const res = await axios.get(`${URL}/admin/customers`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const createCustomer = async (data) => {
  try {
    const res = await axios.post(`${URL}/admin/customer`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteCustomer = async (id) => {
  try {
    const res = await axios.delete(`${URL}/admin/customer/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateCustomer = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/admin/customer/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error];
  }
};

export const createCustomerGroup = async (data) => {
  try {
    const res = await axios.post(`${URL}/admin/customerGroup`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error?.response?.data?.message];
  }
};

export const getAllCustomerGroups = async () => {
  try {
    const res = await axios.get(`${URL}/admin/customerGroups`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const deleteCustomerGroup = async (id) => {
  try {
    const res = await axios.delete(`${URL}/admin/customerGroup/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getProductData = async () => {
  try {
    const res = await axios.get(`${URL}/admin/products`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getProuductById = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/product/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const createProduct = async (data) => {
  try {
    const res = await axios.post(`${URL}/admin/product`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const updateProduct = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/admin/product/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const deleteProduct = async (id) => {
  try {
    const res = await axios.delete(`${URL}/admin/product/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getCustomersById = async (id) => {
  try {
    const res = await axios.get(`${URL}/customer-data/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const clientDatabyid = async (id, start, end) => {
  try {
    const res = await axios.get(
      start && end
        ? `${URL}/customer/get/${id}?startDate=${start}T00:00:00.000Z&endDate=${end}T23:59:59.999Z`
        : `${URL}/customer/get/${id}`
    );
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e];
  }
};

export const getCustomerGroupById = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/customerGroup/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getAllOrder = async () => {
  try {
    const res = await axios.get(`${URL}/orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e];
  }
};

//client

export const getAllCustomer = async (token) => {
  try {
    const res = await axios.get(`${URL}/customer/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};

export const getAccessCustomer = async (token) => {
  try {
    const res = await axios.get(`${URL}/customer/access`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};

export const getAdminCustomerAccess = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/customer-access/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};

export const getSubCustomerById = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/customer-all/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};

//staff marketing

export const createMarketingOrder = async (data) => {
  try {
    const res = await axios.post(`${URL}/staff/marketing/order`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const getMarketDashboardHandler = async () => {
  try {
    const res = await axios.get(`${URL}/staff/marketing/dashboard`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const completeOrder = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/order-complete/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const deleteOrder = async (id) => {
  try {
    const res = await axios.delete(`${URL}/order/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const updateOrder = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/order/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const createPayment = async (data) => {
  try {
    const res = await axios.post(`${URL}/staff/marketing/payment`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const createPaymentadmin = async (data) => {
  try {
    const res = await axios.post(`${URL}/admin/staff-payment`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const createStaffSalary = async (data) => {
  try {
    const res = await axios.post(`${URL}/admin/salary`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const updateStaffpayment = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/admin/staff-payment/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const getTradingStaffOrder = async () => {
  try {
    const res = await axios.get(`${URL}/staff/trading/orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};
export const getOthersStaffOrder = async () => {
  try {
    const res = await axios.get(`${URL}/staff/other/orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};
export const getAllTagOrders = async () => {
  try {
    const res = await axios.get(`${URL}/staff/tag/orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};

export const getStancilStaff = async () => {
  try {
    const res = await axios.get(`${URL}/staff/stancil/orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error];
  }
};

export const updatePayment = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/payment/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const updateStaffSalary = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/admin/salary/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const clientgetCustomerById = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/customer-all/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const deletePayment = async (id) => {
  try {
    const res = await axios.delete(`${URL}/payment/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};
export const deleteSalary = async (id) => {
  try {
    const res = await axios.delete(`${URL}/admin/salary/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const deletePurchaseOrder = async (id) => {
  try {
    const res = await axios.delete(`${URL}/purchase-order/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};
export const createPurchaseOrder = async (data) => {
  try {
    const res = await axios.post(`${URL}/staff/marketing/purchase-order`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getDashboardCount = async (type) => {
  try {
    const res = await axios.post(`${URL}/admin/dashboard-count`, {
      type,
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};
export const getDashboardSale = async (type) => {
  try {
    const res = await axios.post(`${URL}/admin/dasboard-sale`, {
      type,
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};
export const getDashboardPurchase = async (type) => {
  try {
    const res = await axios.post(`${URL}/admin/dashboard-purchase`, {
      type,
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};
export const getDashboardSalary = async () => {
  try {
    const res = await axios.get(`${URL}/admin/salary`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};
export const getCustomerGroup = async () => {
  try {
    const res = await axios.get(`${URL}/admin/customerGroups-detail`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getCustomerClientid = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/customer-all/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getCustomerDashData = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/customerGroup-customers/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getDashboardProfit = async (type) => {
  try {
    const res = await axios.post(`${URL}/admin/dashboard-profit`, {
      type,
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const updatePurchaseOrder = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/purchase-order/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const resetcustomer = async (id) => {
  try {
    const res = await axios.get(`${URL}/admin/customer-reset/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const cleanCustomer = async () => {
  try {
    const res = await axios.get(`${URL}/admin/clean-customer`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const dashboardCompletedOrders = async () => {
  try {
    const res = await axios.get(`${URL}/completed-orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const dashboardPurchaseOrders = async () => {
  try {
    const res = await axios.get(`${URL}/purchase-orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};
export const admindashboardPurchaseOrders = async () => {
  try {
    const res = await axios.get(`${URL}/admin-purchase-orders`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const dashboardPayments = async () => {
  try {
    const res = await axios.get(`${URL}/payments`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const createCustomerOrder = async (token, data) => {
  try {
    const res = await axios.post(`${URL}/customer/order`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const getPendingOrder = async (id) => {
  try {
    const res = await axios.get(`${URL}/customer-pending/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e.response.data.message];
  }
};

export const getProductPriceByCustomer = async (data) => {
  try {
    const res = await axios.post(`${URL}/customer-product-value`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const getProductGstByCustomer = async (data) => {
  try {
    const res = await axios.post(`${URL}/customer-product-gst`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const addRateToCusPro = async (data) => {
  try {
    const res = await axios.post(`${URL}/add/customer-product`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const addGSTToCusPro = async (data) => {
  try {
    const res = await axios.post(`${URL}/add/customer-gst`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const updateRateToCusPro = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/customer-product/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const updateGSTToCusPro = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/customer-gst/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const deleteRateToCusPro = async (id) => {
  try {
    const res = await axios.delete(`${URL}/customer-product/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const deleteGstToCusPro = async (id) => {
  try {
    const res = await axios.delete(`${URL}/customer-gst/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const getRate = async (data) => {
  try {
    const res = await axios.post(`${URL}/customer-product`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};
export const getGst = async (data) => {
  try {
    const res = await axios.post(
      `${URL}/customer-gst
    `,
      data
    );
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const showbanner = async (id) => {
  try {
    const res = await axios.get(`${URL}/order-banner/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e];
  }
};

export const getallpendingorder = async (token) => {
  try {
    const res = await axios.get(`${URL}/customer/all-pending`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getallpendingorderbyid = async (id) => {
  try {
    const res = await axios.get(`${URL}/customer/get-pending/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getallSuperAdmin = async () => {
  try {
    const res = await axios.get(`${URL}/superadmin/dashboard`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (e) {
    return [false, e];
  }
};

export const getallsuperStaff = async () => {
  try {
    const res = await axios.get(`${URL}/superadmin/staffs`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e];
  }
};

export const getallsuperStaffbyid = async (id) => {
  try {
    const res = await axios.get(`${URL}/superadmin/staff/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (e) {
    return [false, e];
  }
};

export const addSuperStaff = async (data) => {
  try {
    const res = await axios.post(`${URL}/superadmin/staff`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (err) {
    return [false, err.response.data];
  }
};

export const staffSendMessage = async (data) => {
  try {
    const res = await axios.post(`${URL}/superadmin/message`, {
      data: data,
    });
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch (err) {
    return [false, err.response.data];
  }
};

export const addPaymentToSuper = async (data) => {
  try {
    const res = await axios.post(`${URL}/superadmin/payment`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const addReceivedToSuper = async (data) => {
  try {
    const res = await axios.post(`${URL}/superadmin/received`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const deleteSuperStaff = async (id) => {
  try {
    const res = await axios.delete(`${URL}/superadmin/staff/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const updateSuperStaffPayment = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/superadmin/payment/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const updateSuperStaffReceived = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/superadmin/received/${id}`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const deleteSuperStaffPayment = async (id) => {
  try {
    const res = await axios.delete(`${URL}/superadmin/payment/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const deleteSuperStaffReceived = async (id) => {
  try {
    const res = await axios.delete(`${URL}/superadmin/received/${id}`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};

export const searchSuperStaff = async (data) => {
  try {
    const res = await axios.post(`${URL}/superadmin/search-staff`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};
export const searchCustomer = async (data) => {
  try {
    const res = await axios.post(`${URL}/admin/customer-search`, data);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data.message];
  } catch {
    return [false, "Something went wrong"];
  }
};
