import { Button, Modal } from "@mui/material";
import React from "react";

const Success = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div
        className="w-[95%] md:w-[15rem]  bg-[#f8abad] border-2 border-black rounded-lg  py-6 px-6 flex flex-col gap-6
            top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute
        "
      >
        <div className="flex flex-col justify-center items-center gap-4">
          <h1 className="text-2xl  text-green-700">SUCCESSFUL</h1>
          <Button
            variant="contained"
            color="primary"
            className="w-1/3 "
            onClick={() => {
              setOpen(false);
            }}
          >
            ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Success;
