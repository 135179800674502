import { useState } from "react";
import logo from "../assets/logo.png";
import CustomInput from "../components/CustomInput";
import { resetPaswsord } from "../utils/api";
import { BiError } from "react-icons/bi";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "@mui/material";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmError, setConfirmError] = useState(null); // [1
  const navigate = useNavigate();
  const changePasswordHandler = async () => {
    if (data.newPassword !== data.confirmPassword) {
      setConfirmError("Password does not match");
      return;
    }
    if (
      !data.userName ||
      !data.oldPassword ||
      !data.newPassword ||
      !data.confirmPassword
    ) {
      return;
    }
    setLoading(true);
    const [success, res] = await resetPaswsord(data);
    if (success) {
      toast.success("Password Changed Successfully");
      navigate("/login");
    } else {
      setError(res.response.data);
    }
    setLoading(false);
  };

  return (
    <div className="h-[100dvh] bg-gray-100 flex justify-center items-center">
      {loading && <Loading />}
      <div className="w-[20rem]  bg-white rounded-lg  py-10 px-5 flex flex-col gap-6">
        <img src={logo} alt="logo" className="w-5/6  mx-auto" />
        <div className="flex flex-col gap-5 ">
          <h1 className="text-xl font-semibold">Change Password</h1>
          <div className="flex flex-col gap-3 ">
            <CustomInput
              title="User Name"
              onChange={(e) => {
                setData((prev) => ({ ...prev, userName: e.target.value }));
              }}
            />
            <div className="flex flex-col gap-1">
              <CustomInput
                type={"password"}
                title="Old Password"
                onChange={(e) => {
                  setData((prev) => ({ ...prev, oldPassword: e.target.value }));
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <CustomInput
                type={"password"}
                title="New Password"
                onChange={(e) => {
                  setData((prev) => ({ ...prev, newPassword: e.target.value }));
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <CustomInput
                type={"password"}
                title="Confirm New Password"
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    confirmPassword: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={changePasswordHandler}
        >
          Change Password
        </Button>
        <div>
          Help:{" "}
          <a
            href="https://api.whatsapp.com/send?phone=919417771111&text=Hello"
            className="text-sm text-blue-800"
            target="_blank"
            rel="noreferrer"
          >
            +919417771111
          </a>
        </div>
      </div>
      <Modal open={error} onClose={() => setError(null)}>
        <div className="bg-white w-[20rem] mx-auto mt-20 p-5 rounded-lg">
          <BiError className="text-red-500 text-4xl" />
          <h1 className="text-xl font-semibold">Error</h1>
          <p className="text-red-500">{error?.message}</p>
        </div>
      </Modal>
      <Modal open={confirmError} onClose={() => setConfirmError(null)}>
        <div className="bg-white w-[20rem] mx-auto mt-20 p-5 rounded-lg">
          <BiError className="text-red-500 text-4xl" />
          <h1 className="text-xl font-semibold">Error</h1>
          <p className="text-red-500">
            Please Enter Same New Password and Confirm Password
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePassword;
