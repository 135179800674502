import React from "react";
import { Link } from "react-router-dom";

const Table = ({ columns, data, type }) => {
  console.log(data);
  return (
    <div className="w-full overflow-x-auto ">
      <table className="table-auto border-separate border-spacing-4">
        <thead>
          <tr>
            {columns?.map((column, index) =>
              column?.title === "PDF" ? (
                <th
                  key={index}
                  className="border whitespace-nowrap border-black px-2 py-1 bg-red-600"
                >
                  <Link
                    to={
                      column.link
                        ? column.link
                        : type
                        ? `/pdf/staff/${type}`
                        : `/pdf/staff/market`
                    }
                    target="_blank"
                    className="text-white"
                  >
                    PDF
                  </Link>
                </th>
              ) : (
                <th
                  key={index}
                  className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400"
                >
                  {column?.title}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((item, index) => (
              <tr key={index}>
                {columns.map((column, index) =>
                  column?.render ? (
                    <td
                      key={index}
                      className={`px-2 py-1 whitespace-nowrap border
                   
                    border-black`}
                    >
                      {column?.render(item)}
                    </td>
                  ) : column?.title === "PDF" ? (
                    <td></td>
                  ) : column?.td ? (
                    column?.td(item)
                  ) : (
                    <td
                      key={index}
                      className=" px-2 py-1 whitespace-nowrap border border-black"
                    >
                      {item?.[column?.dataIndex]}
                    </td>
                  )
                )}
              </tr>
            ))}
          {data?.length === 0 && (
            <tr>
              <td
                className="border border-black px-2 py-1"
                colSpan={columns?.length}
              >
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
