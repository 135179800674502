import { useEffect, useState } from "react";
import Head from "../Head";
import Sidebar from "./Sidebar";
import useStore from "../../utils/store";
import { useNavigate } from "react-router-dom";

const Layout5 = ({ children, onClick, text, title, reload }) => {
  const [open, setOpen] = useState(false);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user?.type !== "Superadmin") {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="md:flex min-h-[100dvh]  bg-layoutbg">
      <Sidebar
        open={open}
        setOpen={setOpen}
        links={[
          {
            title: "Dashboard",
            path: "/superadmin",
          },
          {
            title: "Payment",
            path: "/superadmin/payment",
          },
          {
            title: "Staff",
            path: "/superadmin/staff",
          },
          {
            title: "Staff Add",
            path: "/superadmin/staff/add",
          },
          {
            title: "Logout",
            path: "/logout",
          },
        ]}
      />

      <div className="w-full md:overflow-y-auto ">
        <Head
          onClick={onClick}
          text={text}
          title={title}
          menu={() => setOpen(true)}
          reload={reload}
        />

        <div className="px-6 py-4  ">{children}</div>
      </div>
    </div>
  );
};

export default Layout5;
