import React, { useEffect } from "react";

import CustomModal, { ModalInput, ModalSelect } from "../../components/Modal";
import {
  createStaff,
  deleteSuperStaff,
  deleteSuperStaffPayment,
  deleteSuperStaffReceived,
  getallsuperStaff,
  getallsuperStaffbyid,
  updateSuperStaff,
  updateSuperStaffPayment,
  updateSuperStaffReceived,
} from "../../utils/api";
import Success from "../../components/Success";
import { GrFormClose } from "react-icons/gr";
import { Button, CircularProgress } from "@mui/material";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import Dash from "../../components/Dash";
import Table from "../../components/Table";
import useStore from "../../utils/store";
import DeleteModal from "../../components/modals/DeleteModal";
import Layout5 from "../../components/Layout/Layout5";
import ApiSearch from "../../components/ApiSearch";
import { useParams } from "react-router-dom";
const SuperStaff = () => {
  const [open, setOpen] = React.useState(false);
  const user = useStore((state) => state.user);
  const [staff, setStaff] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [newStaff, setNewStaff] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const initialPayment = {
    staffId: "",
    description: "",
    amount: "",
    paymentType: "",

    completedById: user?.admin?._id,
  };
  const [newPayment, setNewPayment] = React.useState(initialPayment);
  const getStaff = async () => {
    setLoading(true);
    const [success, data] = await getallsuperStaff();
    if (success) {
      setStaff(data);
    }
    setLoading(false);
  };

  const params = useParams();
  const createNewStaff = async () => {
    if (!newStaff.userName || !newStaff.password || !newStaff.role) {
      alert("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] = await createStaff(newStaff);
    if (success) {
      setOpen(false);
      setNewStaff({});
      setSuccess(true);
      getStaff();
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getStaff();
  }, []);

  React.useEffect(() => {
    if (params?.id && staff?.length > 0) {
      setSelected(staff?.find((item) => item?._id === params?.id));
    }
  }, [params?.id, staff]);

  return (
    <Layout5>
      {loading && <Loading />}
      <div className="flex gap-3 flex-col md:flex-row">
        <ApiSearch
          data={staff}
          item={(item) => {
            setSelected(item);
          }}
          active={selected?._id}
        />

        <EditStaff item={selected} reload={getStaff} setItem={setSelected} />
      </div>

      <Success open={success} setOpen={setSuccess} />
    </Layout5>
  );
};

export default SuperStaff;

const EditStaff = ({ item, reload, setItem }) => {
  const [edit, setEdit] = React.useState(false);
  const [edit2, setEdit2] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [newStaff, setNewStaff] = React.useState(item);

  const [staff, setStaff] = React.useState(null);
  const [data, setData] = React.useState(null);

  const getStaff = async () => {
    setLoading(true);
    const [success, data] = await getallsuperStaffbyid(item?._id);
    if (success) {
      setStaff(data);
    }
    setLoading(false);
  };

  const updateStaffHandler = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", newStaff?.name);
    formData.append("phoneNumber", newStaff?.phoneNumber);
    formData.append("address", newStaff?.address ?? "");
    formData.append("interest", newStaff?.interest);
    formData.append("freeDay", newStaff?.freeDay);
    formData.append("defaulter", newStaff?.defaulter);

    formData.append("photo1", newStaff?.photo1);

    formData.append("photo2", newStaff?.photo2);

    if (newStaff?.referrenceStaff) {
      formData.append(
        "referrenceStaff",
        JSON.stringify(newStaff?.referrenceStaff)
      );
    }

    const [success, data] = await updateSuperStaff(item?._id, formData);
    if (success) {
      setItem({
        ...item,
        ...newStaff,
      });

      reload();
      toast.success("Updated");
    }
    setLoading(false);
  };

  useEffect(() => {
    setData(null);
    getStaff();
    setNewStaff(item);
  }, [item]);

  return (
    <div className="flex flex-col gap-10 w-full">
      {loading && <Loading />}

      {staff && (
        <div className="flex-1 flex flex-col w-full gap-6">
          <div className="flex gap-2 flex-col md:flex-row">
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                USERNAME
              </div>
              <div className="border border-black px-2 py-1 ">{item?.name}</div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Phone Number
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.phoneNumber}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Address
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.address}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Is Defaulter
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.defaulter ? "Defaulter" : "Not Defaulter"}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 w-fit ">
                Actions
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => {
                    setEdit(!edit);
                  }}
                  className="border border-black px-4 py-1 bg-yellow-400 text-xl "
                >
                  Edit
                </button>
                <button
                  onClick={async () => {
                    setLoading(true);
                    const [success, data] = await deleteSuperStaff(item?._id);
                    if (success) {
                      setItem(null);
                      reload();
                      toast.success("Deleted");
                    }
                    setLoading(false);
                  }}
                  className="border border-black  py-1 bg-red-600 px-4 text-xl "
                >
                  {loading ? "Loading..." : "Delete"}
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1  flex-col md:flex-row flex gap-6">
            <button
              onClick={() => {
                setData(staff?.payment);
              }}
              className="flex-1"
            >
              <Dash title={"Payment"} value={staff?.totalPayment?.toFixed(2)} />
            </button>
            <button
              onClick={() => {
                setData(staff?.received);
              }}
              className="flex-1"
            >
              <Dash
                title={"Received"}
                value={staff?.totalRecived?.toFixed(2)}
              />
            </button>

            <button className="flex-1">
              <Dash
                title={"Interest"}
                value={staff?.totalInterest?.toFixed(2)}
              />
            </button>
            <button className="flex-1">
              <Dash title={"Balance"} value={staff?.totalBalance?.toFixed(2)} />
            </button>
          </div>
          {data && (
            <div className="mt-3 md:w-[calc(100dvw-30rem)]">
              <h1 className="text-2xl font-semibold">
                {data?.[0]?.paymentType
                  ? data?.[0]?.paymentType
                  : "Purchase Items"}
              </h1>
              {data?.[0]?.paymentType ? (
                <Table
                  data={data}
                  columns={[
                    {
                      title: "Date",
                      dataIndex: "createdAt",
                      render(item) {
                        return new Date(item?.createdAt).toLocaleDateString();
                      },
                    },

                    {
                      title: "Description",
                      dataIndex: "description",
                    },
                    {
                      title: "Address",
                      dataIndex: "address",
                    },
                    {
                      title: "Total",
                      dataIndex: "amount",
                      render(item) {
                        return item?.amount?.toFixed(2);
                      },
                    },

                    {
                      title: "Actions",
                      dataIndex: "actions",
                      render(item) {
                        return (
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-2 ">
                              <button
                                onClick={() => {
                                  setOpen(true);
                                  setEdit2({
                                    ...item,
                                    staffId: staff?._id,
                                  });
                                }}
                                className="border border-black px-4 py-1 bg-yellow-400 text-xl "
                              >
                                Edit
                              </button>
                              <DeleteModal
                                onClick={async () => {
                                  setLoading(true);
                                  const [success, data] =
                                    item?.paymentType === "Payment"
                                      ? await deleteSuperStaffPayment(item?._id)
                                      : await deleteSuperStaffReceived(
                                          item?._id
                                        );
                                  if (success) {
                                    setData(null);
                                    getStaff();
                                    toast.success("Deleted");
                                  }
                                  setLoading(false);
                                }}
                                loading={loading}
                              />
                              {/* <button
                              
                                className="border border-black  py-1 bg-red-600 px-4 text-xl "
                              >
                                {loading ? "Loading..." : "Delete"}
                              </button> */}
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "PDF",
                      link: `/super-admin/pdf/staff/${data?.[0]?.paymentType}/${item?._id}`,
                    },
                  ]}
                />
              ) : (
                <Table
                  data={data}
                  columns={[
                    {
                      title: "Date",
                      dataIndex: "createdAt",
                      render(item) {
                        return new Date(item?.createdAt).toLocaleDateString();
                      },
                    },
                    {
                      title: "Customer",
                      accessor: "customer",
                      render(item) {
                        return item?.customer?.userName;
                      },
                    },

                    {
                      title: "Description",
                      dataIndex: "description",
                    },
                    {
                      title: "Location",
                      dataIndex: "location",
                      render(item) {
                        return item?.customer?.group?.name;
                      },
                    },
                    {
                      title: "Product Name",
                      dataIndex: "location",
                      render(item) {
                        return item?.product?.productName;
                      },
                    },
                    {
                      title: "Qnt",
                      dataIndex: "quantity",
                    },
                    {
                      title: "Rate",
                      dataIndex: "rate",
                    },
                    {
                      title: "Gst",
                      dataIndex: "gst",
                    },
                    {
                      title: "Total",
                      dataIndex: "totalAmount",
                      render(item) {
                        return item?.totalAmount?.toFixed(2);
                      },
                    },
                    {
                      title: "User",
                      dataIndex: "completedByName",
                    },

                    {
                      title: "PDF",
                      link: `/pdf/admin/staff/Balance/${item?._id}`,
                    },
                  ]}
                />
              )}
            </div>
          )}
        </div>
      )}
      {edit && (
        <div className="flex flex-col gap-6 items-start border bg-secondary border-black p-5 ">
          <button onClick={() => setEdit(false)} className="ml-auto ">
            <GrFormClose className="text-3xl " />
          </button>

          <div className="flex  gap-2">
            <div className="flex flex-col  gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                USERNAME
              </div>
              <div className="border border-black px-2 py-1 ">{item?.name}</div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-3 flex-col md:flex-row md:items-center justify-between">
              <label>Is Defaulter</label>
              <select
                value={newStaff?.defaulter}
                onChange={(e) => {
                  setNewStaff({
                    ...newStaff,
                    defaulter: e.target.value,
                  });
                }}
                className={`
       
        border-2 border-black md:w-64 py-1 `}
              >
                <option value={true}>Defaulter</option>
                <option value={false}>Not Defaulter</option>
              </select>
            </div>
            <ModalInput
              bg={"bg-white"}
              title="Name"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              value={newStaff?.name}
            />
            <ModalInput
              bg={"bg-white"}
              title="Phone Number"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  phoneNumber: e.target.value,
                }));
              }}
              value={newStaff?.phoneNumber}
            />
            <ModalInput
              bg={"bg-white"}
              title="Address"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  address: e.target.value,
                }));
              }}
              value={newStaff?.address}
            />

            <ModalInput
              bg={"bg-white"}
              title="Interest"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  interest: e.target.value,
                }));
              }}
              value={newStaff?.interest}
            />
            <ModalInput
              bg={"bg-white"}
              title="Free Day"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  freeDay: e.target.value,
                }));
              }}
              value={newStaff?.freeDay}
            />
            <ModalInput
              bg={"bg-white"}
              title="Photo1"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  photo1: e.target.value,
                }));
              }}
              type="file"
            />
            <ModalInput
              bg={"bg-white"}
              title="Photo2"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  photo2: e.target.value,
                }));
              }}
              type="file"
            />
            <div className="flex border p-2 rounded-lg border-black flex-col gap-4">
              <button
                type="button"
                className="bg-primary  text-white py-1 px-4 rounded"
                onClick={() => {
                  if (
                    newStaff?.referrenceStaff &&
                    newStaff?.referrenceStaff?.length >= 2
                  ) {
                    return;
                  }
                  setNewStaff({
                    ...newStaff,
                    referrenceStaff: newStaff?.referrenceStaff
                      ? [...newStaff?.referrenceStaff, {}]
                      : [{}],
                  });
                }}
              >
                Add Referral
              </button>
              {newStaff?.referrenceStaff?.map((item, index) => {
                return (
                  <div
                    className="flex flex-col gap-3 border p-2 border-black rounded-lg"
                    key={index}
                  >
                    <ModalInput
                      title="Name"
                      onChange={(e) => {
                        setNewStaff({
                          ...newStaff,
                          referrenceStaff: newStaff?.referrenceStaff?.map(
                            (item2, index2) => {
                              if (index === index2) {
                                return {
                                  ...item2,
                                  name: e.target.value,
                                };
                              } else {
                                return item2;
                              }
                            }
                          ),
                        });
                      }}
                      value={newStaff?.referrenceStaff?.[index]?.name}
                    />
                    <ModalInput
                      title="Phone Number"
                      type="number"
                      onChange={(e) => {
                        setNewStaff({
                          ...newStaff,
                          referrenceStaff: newStaff?.referrenceStaff?.map(
                            (item2, index2) => {
                              if (index === index2) {
                                return {
                                  ...item2,
                                  phoneNumber: e.target.value,
                                };
                              } else {
                                return item2;
                              }
                            }
                          ),
                        });
                      }}
                      value={newStaff?.referrenceStaff?.[index]?.phoneNumber}
                    />
                    <ModalInput
                      title="Address"
                      onChange={(e) => {
                        setNewStaff({
                          ...newStaff,
                          referrenceStaff: newStaff?.referrenceStaff?.map(
                            (item2, index2) => {
                              if (index === index2) {
                                return {
                                  ...item2,
                                  address: e.target.value,
                                };
                              } else {
                                return item2;
                              }
                            }
                          ),
                        });
                      }}
                      value={newStaff?.referrenceStaff?.[index]?.address}
                    />

                    <ModalInput
                      title="Card Number"
                      type="number"
                      onChange={(e) => {
                        setNewStaff({
                          ...newStaff,
                          referrenceStaff: newStaff?.referrenceStaff?.map(
                            (item2, index2) => {
                              if (index === index2) {
                                return {
                                  ...item2,
                                  cardNumber: e.target.value,
                                };
                              } else {
                                return item2;
                              }
                            }
                          ),
                        });
                      }}
                      value={newStaff?.referrenceStaff?.[index]?.cardNumber}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={updateStaffHandler}
          >
            {loading ? <CircularProgress size={20} /> : "Update"}
          </Button>
        </div>
      )}

      <CustomModal
        open={open}
        setOpen={setOpen}
        onClick={async () => {
          const [success, data] =
            edit2.paymentType === "Payment"
              ? await updateSuperStaffPayment(edit2?._id, edit2)
              : await updateSuperStaffReceived(edit2?._id, edit2);
          if (success) {
            setEdit2(null);
            setOpen(false);
            setData(null);
            getStaff();
            toast.success("Updated Staff");
          }
        }}
      >
        <ModalInput
          title={"Description"}
          value={edit2?.description}
          onChange={(e) => {
            setEdit2({
              ...edit2,
              description: e.target.value,
            });
          }}
        />

        <ModalInput
          title={"Amount"}
          value={edit2?.amount}
          onChange={(e) => {
            setEdit2({
              ...edit2,
              amount: e.target.value,
            });
          }}
        />
        {/* <div className="flex items-center justify-between gap-2">
          <div className="">Payment</div>
          <CustomSelect
            width={"255px"}
            onChange={(e) => {
              setEdit2({
                ...edit2,
                paymentType: e.target.value,
              });
            }}
            options={[
              { value: "Received", label: "Received" },
              { value: "Payment", label: "Payment" },
              { value: "Salary", label: "Salary" },
            ]}
            value={edit2?.paymentType}
            disabled={edit2?.paymentType === "Salary"}
          />
        </div> */}
      </CustomModal>
    </div>
  );
};
