import { Button, Modal } from "@mui/material";
import React from "react";
import CustomModal from "../Modal";

const DeleteModal = ({ onClick, loading }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
      <CustomModal
        open={open}
        setOpen={setOpen}
        onClick={onClick}
        title={"Are you sure you want to delete this?"}
        button={
          <div className="flex gap-4">
            <Button
              variant="contained"
              color="error"
              disabled={loading}
              className="w-1/3"
              onClick={onClick}
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="w-1/3"
              disabled={loading}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </div>
        }
      ></CustomModal>
    </div>
  );
};

export default DeleteModal;
