import { Button } from "@mui/material";
import { useState } from "react";
import { RiMenu2Line } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { cleanCustomer } from "../utils/api";
import Confirm2 from "./modals/Confirm2";

const Head = ({ text, onClick, title, menu, reload }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <div className="flex justify-between px-6 pb-3 pt-8 text-primary font-semibold text-xl sticky top-0 bg-layoutbg">
      <div className="flex items-center gap-4  ">
        <button
          onClick={menu}
          className="md:hidden bg-primary rounded-full p-2"
        >
          <RiMenu2Line className="text-2xl text-white" />
        </button>
        {title ? title : "Admin"}
      </div>
      {text && (
        <Button variant="contained" color="primary" onClick={onClick}>
          {text}
        </Button>
      )}
      {location.pathname === "/admin" && (
        <>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(true)}
          >
            Reset
          </Button>
          {open && (
            <Confirm2
              open={open}
              setOpen={setOpen}
              onClick={async () => {
                const [success, res] = await cleanCustomer();
                if (success) {
                  toast.success(res);
                  setOpen(false);
                  reload();
                } else {
                  toast.error(res);
                }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Head;
