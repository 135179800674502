import React from "react";
import Table from "./Table";
import { Td } from "../pages/superadmin/Dashboard";
import { Button } from "@mui/material";
import { staffSendMessage } from "../utils/api";
import { toast } from "react-toastify";

const StaffMessage = ({ dashboardCount }) => {
  const [checked, setChecked] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSend = async () => {
    if (isLoading) return;
    if (checked.length < 1)
      return toast.error("Please select at least one user");
    setIsLoading(true);
    const [success, res] = await staffSendMessage(checked);
    if (success) {
      setChecked([]);
      toast.success("Message sent successfully");
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col">
      {checked.length > 0 && (
        <div className="flex justify-between items-center">
          <Button onClick={handleSend} variant="contained" color="primary">
            {isLoading
              ? "Sending..."
              : `Send Message to ${checked.length} ${
                  checked.length > 1 ? "users" : "user"
                }`}
          </Button>
        </div>
      )}
      <Table
        columns={[
          {
            title: "Name",

            td: (item) => Td(item, item?.name, true),
          },
          {
            title: "Phone Number",
            td: (item) => Td(item, item?.phoneNumber),
          },
          {
            title: "Address",
            td: (item) => Td(item, item?.address),
          },
          {
            title: "Card Number",
            td: (item) => Td(item, item?.cardNumber),
          },
          {
            title: "Balance",
            td: (item) => Td(item, item?.balance?.toFixed(2)),
          },
          {
            title: "Day",
            td: (item) => Td(item, item?.day),
          },
          {
            title: "Updated On",
            td: (item) =>
              Td(
                item,
                item?.updatedAt
                  ? new Date(item?.updatedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : "N/A"
              ),
          },
          {
            title: "Message",
            render: (item) => (
              <input
                type="checkbox"
                checked={checked.find((i) => i.id === item._id) ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setChecked([
                      ...checked,
                      {
                        id: item._id,
                        amount: item.balance,
                      },
                    ]);
                  } else {
                    setChecked(checked.filter((i) => i.id !== item._id));
                  }
                }}
              />
            ),
          },
          {
            title: "PDF",
            link: `/super-admin/pdf/dash/${dashboardCount?.type}`,
          },
        ]}
        data={dashboardCount?.data}
      />
    </div>
  );
};

export default StaffMessage;
