import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

import Loading from "../../../components/Loading";
import { toast } from "react-toastify";
import { getallsuperStaffbyid } from "../../../utils/api";

// Create styles

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 10,
  },
  row: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    borderLeft: "1px solid #333",
  },
  row2: {
    flexDirection: "row",
    backgroundColor: "white",
    borderLeft: "1px solid #333",
  },
  headingTab: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingGstTab: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingDateTab: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingDateTab4: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTab2: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTabPUR: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingGstTab2: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingGstTabpur: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingRateTab2: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingRateTabpur: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingDateTab2: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingDateTabpur: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingQntTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingQntTabpur: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTotalTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTotalTabpur: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTab3: {
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTabGreen: {
    flex: 1.4,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabDateGreen: {
    flex: 1.15,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabGreen2: {
    flex: 4.53,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTotalTabGreen: {
    flex: 0.99,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },

  headingTab4: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingGstTab4: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab4: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderLeft: "1px solid #333",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab4: {
    flex: 0.7,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  section: {
    margin: 10,
    marginRight: 10,
    border: "1px solid #333",
    width: "100%",
    flexDirection: "row",
  },
  text: {
    flex: 1,
  },
});
const SuperStaffPdf = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const { type, id } = useParams();
  const params = useParams();
  const getdata = async () => {
    setIsLoading(true);
    const [success, data] = await getallsuperStaffbyid(id);
    // type === "Payment"
    //   ? await getSuper(id)
    //   : type === "Received"
    //   ? await getstaffrecevied(id)
    //   : type === "Salary"
    //   ? await getstaffSalary(id)
    //   : await getstaffbalance(id);
    if (success) {
      if (type === "Payment") {
        setList(data?.payment);
      } else if (type === "Received") {
        setList(data?.received);
      }
    }
    setIsLoading(false);
  };
  //   {
  //     title: "Date",
  //     dataIndex: "createdAt",
  //     render: (record) => record?.createdAt?.slice(0, 10),
  //   },
  //   {
  //     title: "Customer Name",
  //     render: (record) => record?.customer?.userName,
  //   },
  //   {
  //     title: "description",
  //     dataIndex: "description",
  //   },
  //   {
  //     title: "Location",
  //     render: (record) => record?.customer?.group?.name,
  //   },
  //   {
  //     title: "Product Name",
  //     render: (record) => record?.product?.productName,
  //   },
  //   {
  //     title: "Qnt",
  //     dataIndex: "quantity",
  //   },
  //   {
  //     title: "Rate",
  //     dataIndex: "rate",
  //   },
  //   {
  //     title: "Gst",
  //     dataIndex: "gst",
  //   },
  //   {
  //     title: "Total",
  //     dataIndex: "totalAmount",
  //   },
  //   {
  //     title: "PDF",
  //     link: "/admin/sales",
  //   },
  useEffect(() => {
    getdata();
  }, [params.id]);

  return isLoading ? (
    <Loading />
  ) : (
    <PDFViewer
      className="
  "
      style={{ width: "100%", height: "100vh" }}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              borderBottom: "1px solid #333",
            }}
          >
            <View style={styles.row}>
              <Text style={styles.headingDateTab}>Date</Text>

              <Text style={styles.headingTab}>description</Text>

              <Text style={styles.headingTab}>PaymentType</Text>

              <Text style={styles.headingTotalTab}>Total</Text>
            </View>
            {list.map((record, index) => (
              <View key={index}>
                <View style={styles.row}>
                  <Text style={styles.headingDateTabpur}>
                    {new Date(record?.createdAt).getUTCDate()}-
                    {new Date(record?.createdAt).getUTCMonth() + 1}-
                    {new Date(record?.createdAt).getUTCFullYear()}
                  </Text>

                  <Text style={styles.headingTabPUR}>{record.description}</Text>

                  <Text style={styles.headingTabPUR}>
                    {record?.paymentType}
                  </Text>

                  <Text style={styles.headingTotalTabpur}>{record.amount}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default SuperStaffPdf;
