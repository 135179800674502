import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import { ThemeProvider, createTheme } from "@mui/material";
import ChangePassword from "./pages/ChangePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import SuperDashboard from "./pages/superadmin/Dashboard";
import SuperStaff from "./pages/superadmin/Staff";
import StaffAdd from "./pages/superadmin/StaffAdd";
import SuperPayment from "./pages/superadmin/Payment";
import SuperDashboardPdf from "./pages/superadmin/pdf/SuperDashPdf";
import SuperStaffPdf from "./pages/superadmin/pdf/StaffPdf";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1eb14b",
        contrastText: "#fff",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/super-admin/pdf/staff/:type/:id"
            element={<SuperStaffPdf />}
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route
            path="/super-admin/pdf/dash/:type"
            element={<SuperDashboardPdf />}
          />

          {/* superadmin */}

          <Route path="/superadmin" element={<SuperDashboard />} />
          <Route path="/superadmin/staff" element={<SuperStaff />} />
          <Route path="/superadmin/staff/:id" element={<SuperStaff />} />
          <Route path="/superadmin/staff/add" element={<StaffAdd />} />
          <Route path="/superadmin/payment" element={<SuperPayment />} />
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
