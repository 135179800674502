import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import React from "react";

const Sidebar = ({ links, open, setOpen }) => {
  const location = useLocation();
  const ref = React.useRef(null);

  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  return (
    <>
      <div className="w-[15rem] md:flex hidden h-full py-4 px-6  flex-col gap-6">
        <img src={logo} alt="logo" className="w-full mx-auto" />

        <div className="flex flex-col gap-3 whitespace-nowrap">
          {links?.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={` rounded py-1.5 px-4 ${
                location.pathname === item.path
                  ? "bg-primary text-white"
                  : item.path === "/logout"
                  ? "bg-red-600 text-white"
                  : ""
              } `}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>

      <div
        className={`w-[90%] md:hidden transform transition-all duration-300
              ${open ? "translate-x-0 ease-out" : "-translate-x-full ease-in"}
          fixed h-full py-4 px-6 flex md:hidden  flex-col gap-6 z-50 bg-[#eeeeee]`}
        ref={ref}
      >
        <img src={logo} alt="logo" className="w-full mx-auto" />

        <div className="flex flex-col gap-3">
          {links?.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={` rounded py-1.5 px-4
              
              ${
                location.pathname === item.path ? "bg-primary text-white" : ""
              } `}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
