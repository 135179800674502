import React from "react";

import CustomSelect from "../../components/Select";
import { ModalInput } from "../../components/Modal";
import {
  addPaymentToSuper,
  addReceivedToSuper,
  getallsuperStaff,
} from "../../utils/api";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import useStore from "../../utils/store";
import Layout5 from "../../components/Layout/Layout5";

const SuperPayment = () => {
  const user = useStore((state) => state.user);
  const initialPayment = {
    staffId: "",
    description: "",
    amount: "",
    paymentType: "",
  };
  const [loading, setLoading] = React.useState(false);
  const [newPayment, setNewPayment] = React.useState(initialPayment);
  const [customers, setCustomers] = React.useState([]);

  const getCustomers = async () => {
    setLoading(true);
    const [success, data] = await getallsuperStaff();

    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };

  const addPaymentHandler = async () => {
    if (
      newPayment.staffId === "" ||
      newPayment.amount === "" ||
      newPayment.paymentType === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    if (newPayment.paymentType === "Received") {
      const [success, data] = await addReceivedToSuper(newPayment);
      if (success) {
        setNewPayment(initialPayment);
        toast.success("Received added successfully");
      } else {
        toast.error(data);
      }
    }

    if (newPayment.paymentType === "Payment") {
      const [success, data] = await addPaymentToSuper(newPayment);
      if (success) {
        setNewPayment(initialPayment);
        toast.success("Payment added successfully");
      } else {
        toast.error(data);
      }
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getCustomers();
  }, []);
  return (
    <Layout5>
      {loading && <Loading />}

      <div className="flex md:items-center justify-center gap-3 md:flex-row flex-col bg-white py-10 px-10">
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Staff
          </div>
          <CustomSelect
            onChange={(e) => {
              setNewPayment({ ...newPayment, staffId: e.target.value });
            }}
            options={customers.map((customer) => ({
              value: customer._id,
              label: `${customer.name}(${customer.phoneNumber})`,
            }))}
            value={newPayment.staffId}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            description
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.description}
            onChange={(e) => {
              setNewPayment({ ...newPayment, description: e.target.value });
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Payment
          </div>
          <CustomSelect
            onChange={(e) => {
              setNewPayment({ ...newPayment, paymentType: e.target.value });
            }}
            options={[
              { value: "Received", label: "Payment" },
              { value: "Payment", label: "Received" },
            ]}
            value={newPayment.paymentType}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Amount
          </div>
          <ModalInput
            width={"w-full"}
            value={newPayment?.amount}
            onChange={(e) => {
              setNewPayment({ ...newPayment, amount: e.target.value });
            }}
            type={"number"}
          />
        </div>

        <button
          onClick={addPaymentHandler}
          className="border-2 w-full md:w-auto border-black bg-primary text-white py-1 px-2 focus:outline-none"
        >
          Save
        </button>
      </div>
    </Layout5>
  );
};

export default SuperPayment;
