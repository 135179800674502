import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
let store = (set) => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  logout: () => set(() => ({ user: null })),
});
store = persist(store, { name: "SuperAdminprabhu" });
store = devtools(store);

const useStore = create(store);

export default useStore;
