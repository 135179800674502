import React from "react";
import Layout5 from "../../components/Layout/Layout5";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import {
  addSuperStaff,
  getallsuperStaff,
  getallsuperStaffbyid,
} from "../../utils/api";
import CustomModal from "../../components/Modal";
import ApiSearch from "../../components/ApiSearch";
import Loading from "../../components/Loading";
import { useEffect } from "react";
import ImageView from "../../components/modals/ImageView";
const StaffAdd = () => {
  const [loading, setLoading] = React.useState(false);
  const [staff, setStaff] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const getStaff = async () => {
    setLoading(true);
    const [success, data] = await getallsuperStaff();
    if (success) {
      setStaff(data);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getStaff();
  }, []);
  return (
    <Layout5>
      <CreateStaff />
      {loading && <Loading />}
      <div className="flex gap-3 flex-col md:flex-row mt-3">
        <ApiSearch
          data={staff}
          item={(item) => {
            setSelected(item);
          }}
          active={selected?._id}
        />

        <EditStaff item={selected} reload={getStaff} setItem={setSelected} />
      </div>
    </Layout5>
  );
};

export default StaffAdd;

const EditStaff = ({ item, reload, setItem }) => {
  const [loading, setLoading] = React.useState(false);

  const [staff, setStaff] = React.useState(null);

  const getStaff = async () => {
    setLoading(true);
    const [success, data] = await getallsuperStaffbyid(item?._id);
    if (success) {
      setStaff(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStaff();
  }, [item]);

  return (
    <div className="flex flex-col gap-10 w-full">
      {loading && <Loading />}

      {staff && (
        <div className=" flex flex-col w-full gap-6 border p-3 border-black rounded">
          <div className="flex gap-2 flex-col flex-wrap md:flex-row border p-3 border-black">
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                USERNAME
              </div>
              <div className="border border-black px-2 py-1 ">{item?.name}</div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Phone Number
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.phoneNumber}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Address
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.address}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Card Number
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.cardNumber}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Free Day
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.freeDay}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Defaulter
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.defaulter ? "Yes" : "No"}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Interest
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.interest}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Photo1
              </div>

              <ImageView img={item?.photo1} />
            </div>
            <div className="flex flex-col gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                Photo2
              </div>

              <ImageView img={item?.photo2} />
            </div>
          </div>

          {staff?.staff?.referrenceStaff?.[0] && (
            <div className="border p-3 flex flex-col gap-3 border-black">
              <h1 className="text-lg font-semibold"> Staff 1</h1>
              <div className="flex gap-2 flex-col flex-wrap md:flex-row ">
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    USERNAME
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[0]?.name}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    Phone Number
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[0]?.phoneNumber}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    Address
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[0]?.address}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    Card Number
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[0]?.cardNumber}
                  </div>
                </div>
              </div>
            </div>
          )}
          {staff?.staff?.referrenceStaff?.[1] && (
            <div className="border p-3 flex flex-col gap-3 border-black">
              <h1 className="text-lg font-semibold"> Staff 2</h1>
              <div className="flex gap-2 flex-col flex-wrap md:flex-row ">
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    USERNAME
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[1]?.name}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    Phone Number
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[1]?.phoneNumber}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    Address
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[1]?.address}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="border bg-yellow-400 border-black px-2 py-1 ">
                    Card Number
                  </div>
                  <div className="border border-black px-2 py-1 ">
                    {staff?.staff?.referrenceStaff?.[1]?.cardNumber}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CreateStaff = () => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    referrenceStaff: [{}, {}],
  });
  const [alreadyExist, setAlreadyExist] = React.useState(null);
  console.log(alreadyExist);
  const addStaffHandler = async (isForced) => {
    if (
      !data?.name ||
      !data?.phoneNumber ||
      !data?.cardNumber ||
      !data?.interest ||
      !data?.freeDay ||
      !data?.photo1 ||
      !data?.photo2
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    if (isForced) {
      formData.append("isForced", isForced);
    }
    formData.append("name", data?.name);
    formData.append("phoneNumber", data?.phoneNumber);
    formData.append("cardNumber", data?.cardNumber);
    formData.append("interest", data?.interest);
    formData.append("freeDay", data?.freeDay);
    formData.append("photo1", data?.photo1);
    formData.append("photo2", data?.photo2);
    formData.append("address", data?.address ?? "");
    formData.append("defaulter", data?.defaulter ?? false);
    if (data?.referrenceStaff) {
      formData.append("referrenceStaff", JSON.stringify(data?.referrenceStaff));
    }
    const [success, data2] = await addSuperStaff(formData);
    if (success) {
      toast.success("Staff Added Successfully");
      setData({
        referrenceStaff: [{}, {}],
      });
    } else {
      if (data2?.message?.toString().includes("already exist")) {
        setOpen2(true);
        setAlreadyExist(data2);
      } else {
        toast.error(data2?.message?.toString());
      }
    }
    setLoading(false);
  };
  return (
    <>
      <CustomModal
        open={open2}
        setOpen={setOpen2}
        title={"Reference staff already exists"}
        button={<></>}
      >
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Image</h2>
            <img
              src={alreadyExist?.staff?.photo1}
              alt=""
              className="w-20 h-20"
            />
          </div>
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Name</h2>
            <h1 className="text-lg font-semibold">
              {alreadyExist?.staff?.name}
            </h1>
          </div>
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Phone Number</h2>
            <h1 className="text-lg font-semibold">
              {alreadyExist?.staff?.phoneNumber}
            </h1>
          </div>
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Card Number</h2>
            <h1 className="text-lg font-semibold">
              {alreadyExist?.staff?.cardNumber}
            </h1>
          </div>
        </div>
        <div className="flex gap-6">
          <button
            className="bg-primary text-white py-1 px-4 rounded"
            onClick={() => {
              setOpen2(false);
              setOpen(false);
              setData({
                referrenceStaff: [{}, {}],
              });
            }}
          >
            Cancel
          </button>
          <button
            className="bg-primary text-white py-1 px-4 rounded"
            onClick={() => {
              setOpen2(false);
              addStaffHandler(true);
            }}
          >
            Continue With Existing Data
          </button>
        </div>
      </CustomModal>
      <div className="flex justify-end">
        <button
          onClick={() => setOpen(true)}
          className="bg-primary text-white py-1 px-4 rounded "
        >
          Add Staff
        </button>
      </div>
      <CustomModal
        open={open}
        setOpen={setOpen}
        title={"Add Staff"}
        button={<></>}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();

            addStaffHandler();
            e.target.reset();
          }}
          className="  flex flex-col gap-3  rounded"
        >
          <Input
            label="Name"
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
            value={data?.name}
          />
          <Input
            label="Phone Number"
            type="number"
            onChange={(e) => {
              setData({
                ...data,
                phoneNumber: e.target.value,
              });
            }}
            value={data?.phoneNumber}
          />
          <Input
            label="Address"
            onChange={(e) => {
              setData((prev) => ({ ...prev, address: e.target.value }));
            }}
            value={data?.address}
          />
          <Input
            label="Card Number"
            type="number"
            onChange={(e) => {
              setData({
                ...data,
                cardNumber: e.target.value,
              });
            }}
            value={data?.cardNumber}
          />
          <Input
            label="Interest"
            type="number"
            onChange={(e) => {
              setData({
                ...data,
                interest: e.target.value,
              });
            }}
            value={data?.interest}
          />
          <Input
            label="Free Days"
            type="number"
            onChange={(e) => {
              setData({
                ...data,
                freeDay: e.target.value,
              });
            }}
            value={data?.freeDay}
          />
          <Input
            label="Photo 1"
            type="file"
            onChange={(e) => {
              setData({
                ...data,
                photo1: e.target.files[0],
              });
            }}
          />
          <Input
            label="Photo 2"
            type="file"
            onChange={(e) => {
              setData({
                ...data,
                photo2: e.target.files[0],
              });
            }}
          />
          <div className="flex border p-3  border-black flex-col gap-4">
            {data?.referrenceStaff?.map((item, index) => {
              return (
                <div
                  className="flex flex-col gap-3 border p-3 border-black"
                  key={index}
                >
                  <Input
                    label="Name"
                    value={data?.referrenceStaff?.[index]?.name}
                    onChange={(e) => {
                      setData({
                        ...data,
                        referrenceStaff: data?.referrenceStaff?.map(
                          (item2, index2) => {
                            if (index === index2) {
                              return {
                                ...item2,
                                name: e.target.value,
                              };
                            } else {
                              return item2;
                            }
                          }
                        ),
                      });
                    }}
                  />
                  <Input
                    label="Phone Number"
                    type="number"
                    value={data?.referrenceStaff?.[index]?.phoneNumber}
                    onChange={(e) => {
                      setData({
                        ...data,
                        referrenceStaff: data?.referrenceStaff?.map(
                          (item2, index2) => {
                            if (index === index2) {
                              return {
                                ...item2,
                                phoneNumber: e.target.value,
                              };
                            } else {
                              return item2;
                            }
                          }
                        ),
                      });
                    }}
                  />
                  <Input
                    label="Address"
                    value={data?.referrenceStaff?.[index]?.address}
                    onChange={(e) => {
                      setData({
                        ...data,
                        referrenceStaff: data?.referrenceStaff?.map(
                          (item2, index2) => {
                            if (index === index2) {
                              return {
                                ...item2,
                                address: e.target.value,
                              };
                            } else {
                              return item2;
                            }
                          }
                        ),
                      });
                    }}
                  />

                  <Input
                    label="Card Number"
                    type="number"
                    value={data?.referrenceStaff?.[index]?.cardNumber}
                    onChange={(e) => {
                      setData({
                        ...data,
                        referrenceStaff: data?.referrenceStaff?.map(
                          (item2, index2) => {
                            if (index === index2) {
                              return {
                                ...item2,
                                cardNumber: e.target.value,
                              };
                            } else {
                              return item2;
                            }
                          }
                        ),
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <button
            type="submit"
            className="bg-primary text-white py-1 px-4 rounded"
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Add Staff"
            )}
          </button>
        </form>
      </CustomModal>
    </>
  );
};

const Input = ({ label, ...rest }) => {
  return (
    <div className="flex items-center gap-3">
      <label className="text-sm font-semibold w-32" htmlFor={label}>
        {label}
      </label>
      <input
        placeholder={`Enter ${label}`}
        id={label}
        className="border border-gray-300 p-2 rounded"
        {...rest}
      />
    </div>
  );
};
